import Vue from "vue";
import Vuex from "vuex";
import ky from "ky";
import jwtDecode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null as null | string,
  },
  mutations: {
    SET_TOKEN: (state, { token }: { token: string }) => (state.token = token),
  },
  actions: {
    LOGIN: async (
      { commit },
      { login, passwort }: { login: string; passwort: string }
    ) => {
      const auth = btoa(`${login}:${passwort}`);
      commit(
        "SET_TOKEN",
        await ky
          .get(process.env.VUE_APP_AUTH_SERVER_URL as string, {
            headers: {
              Authorization: `Basic ${auth}`,
            },
          })
          .json()
      );
    },
  },
  getters: {
    isLoggedIn: ({ token }) => !!token,
    decodedToken: ({ token }) => (token ? jwtDecode(token) : {}),
    userId: (_, { decodedToken }) => Number(decodedToken?.sub),
  },
  modules: {},
});
