








































































import { Component, Vue } from "vue-property-decorator";
import logout from "@/logout";

@Component({})
export default class App extends Vue {
  drawer = false;
  group = false;

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  logout() {
    logout();
  }
}
