import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import logout from "./logout";

// Es existieren keine Types. für das Modul. Deshalb das `@ts-ignore`.
// Weil eslint allerdings `@ts-ingore` Regeln verbietet,
// müssen wir zunächst eslint deaktivieren, bevor wir TypeScript
// deaktivieren dürfen...
// eslint-disable-next-line
// @ts-ignore
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

Vue.use(Datetime);
Vue.component("datetime", Datetime);

import VueApollo from "vue-apollo";
import ApolloClient from "apollo-boost";
import vuetify from "./plugins/vuetify";

Vue.use(VueApollo);

const headers = {
  get Authorization() {
    return `Bearer ${store.state.token}`;
  },
};

const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPH_SERVER_URL,
  headers,
  onError: (err) => {
    console.error(err);

    const { graphQLErrors } = err;
    if (!graphQLErrors) {
      return;
    }

    const jwtExpired = graphQLErrors.some(({ message }) =>
      message.endsWith("JWTExpired")
    );
    if (jwtExpired) logout();
  },
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
