









































import { Component, Vue } from "vue-property-decorator";
import ky from "ky";

@Component({})
export default class Login extends Vue {
  loading = false;
  show = false;
  snackbar = false;
  snackbarText = "";

  user = "";
  passwort = "";

  usernameError = false;
  passwordError = false;

  required = (val: string) => !!val || "Notwendiges Feld";

  get loginPossible() {
    return !!this.user && !!this.passwort;
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  async login() {
    this.loading = true;
    try {
      await this.$store.dispatch("LOGIN", {
        login: this.user,
        passwort: this.passwort,
      });
    } catch (err) {
      if (err instanceof ky.HTTPError) {
        // Dreckig aber besser als ein Fehlerhaftes UI weil wir hier zu faul
        // waren alle Fehlerfälle abzufangen
        try {
          const { reason } = await err.response.json();
          switch (reason) {
            case "Username":
              this.snackbarText = "Falscher Benutzername.";
              this.usernameError = true;
              break;
            case "Password":
              this.snackbarText = "Falsches Passwort.";
              this.passwordError = true;
              break;
            default:
              this.snackbarText = `Unbekanntes Serverproblem.`;
          }
        } catch (e) {
          console.error(`Error while parsing HTTPError`, e);
        }
      }
      this.snackbar = true;
    } finally {
      this.loading = false;
    }

    if (this.isLoggedIn) this.$router.push({ name: "Home" });
  }
}
