



































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { formatDate } from "@/utils";

/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/camelcase  */
type MaschineSelection = null | {
  arbeitsplatzs: any[];
  maschine_id: number;
};

type ArbeitsplatzSelection = null | {
  ermoeglichts: { prozess: any }[];
  arbeitsplatz_id: number;
};

type ProzessSelection = null | {
  teil_vons: { vorgang: any }[];
  prozess_id: number;
};

type VorgangSelection = null | {
  arbeitsplatzs: any[];
  vorgang_id: number;
  hat_attributs: { attribut: Attribut }[];
};

type Attribut = { attribut_id: number; bezeichnung: string; type: string };

type UpsertSeed = {
  buchung_id: number;
  auftrag_id: string;
  arbeitsplatz_id: number;
  prozess_id: number;
  vorgang_id: number;
  auftrag_volumen_verarbeitet: number;
  auftrag_volumen: number;
  datum_start: string;
  datum_ende: string;
  bemerkung: string | null;
  arbeitsplatz: {
    maschine_id: number;
  };
  attribut_werts: {
    attribut_id: number;
    wert: unknown;
  }[];
};

const insertQuery = gql`
  mutation InsertBetriebsdaten(
    $arbeitsplatz_id: Int!
    $auftrag_id: String!
    $bemerkung: String
    $datum_ende: timestamp!
    $datum_start: timestamp!
    $prozess_id: Int!
    $vorgang_id: Int!
    $attributs: [attribut_wert_insert_input!]!
    $auftrag_volumen_verarbeitet: Int
    $auftrag_volumen: Int
  ) {
    insert_betriebsdaten_one(
      object: {
        arbeitsplatz_id: $arbeitsplatz_id
        auftrag_id: $auftrag_id
        bemerkung: $bemerkung
        datum_ende: $datum_ende
        datum_start: $datum_start
        prozess_id: $prozess_id
        vorgang_id: $vorgang_id
        auftrag_volumen_verarbeitet: $auftrag_volumen_verarbeitet
        auftrag_volumen: $auftrag_volumen
        attribut_werts: { data: $attributs }
      }
    ) {
      buchung_id
    }
  }
`;

const upsertQuery = gql`
  mutation InsertBetriebsdaten(
    $buchung_id: Int
    $arbeitsplatz_id: Int!
    $auftrag_id: String!
    $bemerkung: String
    $datum_ende: timestamp!
    $datum_start: timestamp!
    $prozess_id: Int!
    $vorgang_id: Int!
    $attributs: [attribut_wert_insert_input!]!
    $auftrag_volumen_verarbeitet: Int
    $auftrag_volumen: Int
  ) {
    insert_betriebsdaten_one(
      object: {
        buchung_id: $buchung_id
        arbeitsplatz_id: $arbeitsplatz_id
        auftrag_id: $auftrag_id
        bemerkung: $bemerkung
        datum_ende: $datum_ende
        datum_start: $datum_start
        prozess_id: $prozess_id
        vorgang_id: $vorgang_id
        auftrag_volumen_verarbeitet: $auftrag_volumen_verarbeitet
        auftrag_volumen: $auftrag_volumen
        attribut_werts: {
          data: $attributs
          on_conflict: { constraint: attribut_wert_pkey, update_columns: wert }
        }
      }
      on_conflict: {
        constraint: betriebsdaten_pkey
        update_columns: [
          auftrag_id
          vorgang_id
          prozess_id
          arbeitsplatz_id
          datum_start
          datum_ende
          bemerkung
          auftrag_volumen_verarbeitet
          auftrag_volumen
        ]
      }
    ) {
      buchung_id
    }
  }
`;

@Component({
  apollo: {
    maschine: {
      query: gql`
        query ErfassungTopologie {
          maschine {
            maschine_id
            bezeichnung
            arbeitsplatzs {
              arbeitsplatz_id
              bezeichnung
              ermoeglichts {
                prozess {
                  prozess_id
                  bezeichnung
                  teil_vons {
                    vorgang {
                      vorgang_id
                      bezeichnung
                      hat_attributs {
                        attribut {
                          attribut_id
                          bezeichnung
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,

      update(data) {
        this.syncPropsToUpsert(data.maschine);
        return data.maschine;
      },
    },

    auftragsIdValidation: {
      query: gql`
        query AuftragsSuche($nummer: Int!) {
          auftrag(where: { nummer: { _eq: $nummer } }) {
            auftrag_id
            position
            kundensw
          }
        }
      `,
      update: (data) => data?.auftrag,
      variables() {
        return {
          nummer: this.auftragsNummer ?? -1,
        };
      },
    },
  },
})
export default class Erfassung extends Vue {
  // Wenn diese Prop gesetzt ist, wird ein Upsert auf der entsprechenden Buchung ausgeführt.
  // So können wir diese Felder einfach für Updates wiederverwenden, ohne ein neues Formular zu benötigen.
  @Prop({ required: false, default: null })
  upsertSeed!: UpsertSeed | null;

  // Es kann sowohl passieren das wir auf `upsertSeed` warten müssen,
  // als auch das `maschine` noch nicht resolved ist. Für ersteren Fall haben
  // wir diesen Watcher, für letzteren callen wir `syncPropsToUpsert` im
  // Apollo Update Hook.
  @Watch("upsertSeed")
  onUpsertSeedChange() {
    this.syncPropsToUpsert(this.maschine);
  }

  syncPropsToUpsert(maschine: any[]) {
    const upsertSeed = this.upsertSeed;
    if (!upsertSeed) return;
    const [nummer, position] = upsertSeed.auftrag_id.split("+");
    this.auftragsNummer = Number(nummer);
    // Während der wiederherstellung ist das Validationsarray noch nicht gefüllt.
    // Damit das Auftragsposition Select Feld trotzdem funktioniert, sobald wir
    // `auftragsId` setzen, füllen wir hier den Validator mit dem wiederherzustellenden Auftrag.
    this.auftragsIdValidation = [
      {
        auftrag_id: upsertSeed.auftrag_id,
        position: Number(position),
      },
    ];
    this.auftragsId = upsertSeed.auftrag_id;
    const sMaschine = maschine.find(
      (m) => m.maschine_id === upsertSeed?.arbeitsplatz?.maschine_id
    );
    const sArbeitsplatz = sMaschine?.arbeitsplatzs?.find(
      (a: any) => a.arbeitsplatz_id === upsertSeed.arbeitsplatz_id
    );
    const sProzess = sArbeitsplatz?.ermoeglichts
      ?.map((e: any) => e.prozess)
      ?.find((p: any) => p.prozess_id === upsertSeed.prozess_id);

    const sVorgang = sProzess?.teil_vons
      ?.map((t: any) => t.vorgang)
      ?.find((v: any) => v.vorgang_id === upsertSeed.vorgang_id);

    this.selectedMaschine = sMaschine;
    this.selectedArbeitsplatz = sArbeitsplatz;
    this.selectedProzess = sProzess;
    this.selectedVorgang = sVorgang;
    this.selectedStartdatum = upsertSeed.datum_start;
    this.selectedEnddatum = upsertSeed.datum_ende;
    this.bemerkung = upsertSeed.bemerkung ?? "";
    this.auftrag_volumen_verarbeitet = upsertSeed.auftrag_volumen_verarbeitet;
    this.auftrag_volumen = upsertSeed.auftrag_volumen;
    this.selectedAttributes = upsertSeed.attribut_werts?.reduce(
      (prev, { attribut_id, wert }) => ({
        ...prev,
        [attribut_id]: wert,
      }),
      {} as any
    );
  }

  get cardTitle() {
    return this.upsertSeed
      ? `Aktualisiere Datensatz ${this.upsertSeed.buchung_id}`
      : "Datenerfassung";
  }

  auftragsNummerProxy: number | null = null;

  get auftragsNummer() {
    return this.auftragsNummerProxy;
  }

  set auftragsNummer(val) {
    // WIr müssen die `auftragsId` invalidieren, weil ansonsten folgender
    // Fall eintreten kann:
    // Nutzer wählt gültige Aufstragsnummer `1` aus.
    // Nutzer wählt gültige Position `1` aus.
    // Nutzer wählt gültige Auftragsnummer `2` aus
    // Nun kann das Fomular abgeschickt werden, allerdings
    // ist die intere `auftragsId === 1+1` anstelle der korrenten`2+1`
    this.auftragsId = null;
    this.auftragsNummerProxy = val;
  }

  auftragsId: string | null = null;

  /* Falls Number ist `auftragsId` in Datenbank vorhanden */
  auftragsIdValidation:
    | null
    | {
        auftrag_id: string;
        position: number;
        kundensw?: string;
        auftrag_volumen?: string;
      }[] = null;

  get auftragsNummerError() {
    if (this.auftragsNummer === null) return [];
    if (this.$apollo.queries.auftragsIdValidation.loading) return [];
    if (this.auftragsIdValidation === null) return [];
    if (this.auftragsIdValidation.length > 0) return [];
    return ["Auftragsnummer nicht bekannt"];
  }

  get auftragsPositionen() {
    return this.auftragsIdValidation ?? [];
  }

  get kunde() {
    return this.auftragsIdValidation
      ?.map(({ kundensw }) => kundensw)
      .find((k) => !!k);
  }

  maschine: any[] = [];
  selectedMaschine: MaschineSelection = null;
  selectedArbeitsplatz: ArbeitsplatzSelection = null;
  selectedProzess: ProzessSelection = null;
  selectedVorgang: VorgangSelection = null;

  // Dieser Watcher ist technisch gesehen überflüssig,
  // weil zwei Maschinen sich nach dem aktuellen Schema
  // niemals einen Arbeitsplatz teilen können.
  // Für die Zukunft und aus Symmetriegründen mit den
  // anderen Properties ist es allerdings hier.
  @Watch("selectedMaschine")
  onSelectedMaschineChange(newVal: MaschineSelection) {
    if (newVal === null) {
      this.selectedArbeitsplatz = null;
      return;
    }

    const ap = this.selectedArbeitsplatz;
    if (ap === null) return;
    const valid = newVal.arbeitsplatzs.find(
      (a) => a.arbeitsplatz_id === ap.arbeitsplatz_id
    );
    if (valid) return;
    this.selectedArbeitsplatz = null;
  }

  @Watch("selectedArbeitsplatz")
  onSelectedArbeitsplatzChange(newVal: ArbeitsplatzSelection) {
    if (newVal === null) {
      this.selectedProzess = null;
      return;
    }

    const pr = this.selectedProzess;
    if (pr === null) return;
    const valid = newVal.ermoeglichts
      .map(({ prozess }) => prozess)
      .find((p) => p.prozess_id === pr.prozess_id);
    if (valid) return;
    this.selectedProzess = null;
  }

  @Watch("selectedProzess")
  onSelectedProzessChange(newVal: ProzessSelection) {
    if (newVal === null) {
      this.selectedVorgang = null;
      return;
    }

    const vg = this.selectedVorgang;
    if (vg === null) return;
    const valid = newVal.teil_vons
      .map(({ vorgang }) => vorgang)
      .find((v) => v.vorgang_id === vg.vorgang_id);
    if (valid) return;
    this.selectedVorgang = null;
  }

  @Watch("selectedVorgang")
  onSelectedVorgangChange(newVal: VorgangSelection) {
    if (newVal === null) {
      this.selectedAttributes = {};
      return;
    }

    // Falls wir den Vorgang wechseln, aber der neuen Vorgang sich mit dem alten
    // Attribute teilt, kopieren wir diese hier rüber.
    const copiedAttributes: any = {};

    newVal.hat_attributs.forEach(
      ({ attribut }) =>
        (copiedAttributes[attribut.attribut_id] = (this
          .selectedAttributes as any)[attribut.attribut_id])
    );

    this.selectedAttributes = copiedAttributes;
  }

  selectedStartdatumProxy = new Date().toISOString();
  selectedEnddatum = new Date().toISOString();

  get selectedStartdatum() {
    return this.selectedStartdatumProxy;
  }

  set selectedStartdatum(newDate) {
    this.selectedStartdatumProxy = newDate;
    if (new Date(newDate) > new Date(this.selectedEnddatum)) {
      this.selectedEnddatum = newDate;
    }
  }

  get selectedStartdatumFormatted() {
    return formatDate(new Date(this.selectedStartdatum));
  }

  get selectedEnddatumFormatted() {
    return formatDate(new Date(this.selectedEnddatum));
  }

  bemerkung = "";
  auftrag_volumen_verarbeitet: number | null = null;
  auftrag_volumen: number | null = null;

  get startPickerOpen() {
    return (this.$refs.dateTimePickerStart as any)?.isOpen;
  }

  set startPickerOpen(newVal) {
    if (!this.$refs.dateTimePickerStart) return;
    (this.$refs.dateTimePickerStart as any).isOpen = newVal;
  }

  get endPickerOpen() {
    return (this.$refs.dateTimePickerEnd as any)?.isOpen;
  }

  set endPickerOpen(newVal) {
    if (!this.$refs.dateTimePickerEnd) return;
    (this.$refs.dateTimePickerEnd as any).isOpen = newVal;
  }

  closeDatetimeStartPicker() {
    this.startPickerOpen = false;
    if (!this.$refs.dateMenuStart) return;
    // [Hack] deactiviert v-menu manuell, da wir externen Datetimepicker aktivieren.
    (this.$refs.dateMenuStart as any).isActive = false;
  }

  closeDatetimeEndPicker() {
    this.endPickerOpen = false;
    if (!this.$refs.dateMenuEnd) return;
    (this.$refs.dateMenuEnd as any).isActive = false;
  }

  get arbeitsplaetze() {
    return this.selectedMaschine?.arbeitsplatzs ?? [];
  }

  get prozesse() {
    return (
      this.selectedArbeitsplatz?.ermoeglichts.map(({ prozess }) => prozess) ??
      []
    );
  }

  get vorgange() {
    return this.selectedProzess?.teil_vons.map(({ vorgang }) => vorgang) ?? [];
  }

  selectedAttributes = {};

  get attribute() {
    return (
      this.selectedVorgang?.hat_attributs?.map(({ attribut }) => attribut) ?? []
    );
  }

  attributeRules = {};

  async validateAttribute(attribut_id: number) {
    const target = (this.$refs[`attr_${attribut_id}`] as Vue[])?.[0] as any;

    if (!target) return true;

    // Wir müssen auf das nächste DOM Update warten,
    // weil wir ansonsten immer den letzten Input
    // validieren. Wir können nicht den Wert einfach validieren,
    // weil ungültige Werte evtl. nicht soweit propagiert werden.
    // Ein Number-Field in Firefox in welches der Nutzer den String "foo"
    // eingegeben hat, hat den Model value "" und ist damit nicht
    // von einem echten leerwert zu unterscheiden.
    // Wir klinken uns daher in vuetifys validation System ein.
    await this.$nextTick();

    const error = target.badInput ? "Ungültige Eingabe" : true;

    this.$set(this.attributeRules, attribut_id, [error]);

    return error === true;
  }

  async validateAttributes() {
    return (
      await Promise.all(
        this.attribute.map(({ attribut_id }) =>
          this.validateAttribute(attribut_id)
        )
      )
    ).every((id) => id);
  }

  get validData() {
    if (this.auftragsId === null) return false;
    if (this.auftragsNummerError.length > 0) return false;
    if (!this.selectedMaschine) return false;
    if (!this.selectedArbeitsplatz) return false;
    if (!this.selectedProzess) return false;
    if (!this.selectedVorgang) return false;
    if (!this.selectedStartdatum) return false;
    if (!this.selectedEnddatum) return false;
    return true;
  }

  snackbar = false;
  snackbarText = "";
  mutationRunning = false;

  async send() {
    if (!this.validData) return;
    if (this.mutationRunning) return;

    const validAttributes = await this.validateAttributes();
    if (!validAttributes) return;

    this.mutationRunning = true;

    const isUpsert = !!this.upsertSeed?.buchung_id;

    const variables = {
      auftrag_id: this.auftragsId,
      vorgang_id: this.selectedVorgang?.vorgang_id,
      prozess_id: this.selectedProzess?.prozess_id,
      arbeitsplatz_id: this.selectedArbeitsplatz?.arbeitsplatz_id,
      datum_start: this.selectedStartdatum,
      datum_ende: this.selectedEnddatum,
      bemerkung: this.bemerkung || null,
      auftrag_volumen_verarbeitet: this.auftrag_volumen_verarbeitet || null,
      auftrag_volumen: this.auftrag_volumen || null,
      attributs: Object.entries(this.selectedAttributes).map(
        ([attribut_id, wert]) => ({
          attribut_id: Number(attribut_id),
          wert,
        })
      ),
    } as any;

    if (isUpsert) {
      variables["buchung_id"] = this.upsertSeed?.buchung_id;
    }

    this.$apollo
      .mutate({
        mutation: isUpsert ? upsertQuery : insertQuery,
        variables,
      })
      .then((data) => {
        const buchungsId = data?.data?.insert_betriebsdaten_one?.buchung_id;
        this.snackbarText = this.upsertSeed
          ? `Datensatz ${buchungsId} erfolgreich aktualisiert`
          : `Buchung erfolgreich mit Id ${buchungsId}`;
        this.snackbar = true;
        this.selectedVorgang = null;
        this.bemerkung = "";
        this.auftrag_volumen_verarbeitet = null;
        this.mutationRunning = false;
        if (this.upsertSeed) this.$router.push({ name: "Betriebsdaten" });
      })
      .catch((error) => {
        this.snackbarText = `Buchung fehlgeschlagen:\n${JSON.stringify(
          error,
          undefined,
          2
        )}`;
        this.snackbar = true;
        this.mutationRunning = false;
      });
  }
}
