






import { Component, Vue } from "vue-property-decorator";
import Erfassung from "@/components/Erfassung.vue"; // @ is an alias to /src

@Component({
  components: {
    Erfassung,
  },
})
export default class Home extends Vue {}
