import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/update/:buchung_id",
    name: "Update",
    component: () =>
      import(/* webpackChunkName: "update" */ "../views/Update.vue"),
  },
  {
    path: "/betriebsdaten",
    name: "Betriebsdaten",
    component: () =>
      import(
        /* webpackChunkName: "betriebsdaten" */ "../views/Betriebsdaten.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const isLoginRoute = to.name === "Login";

  if (isLoggedIn && isLoginRoute) next({ name: "Home" });
  else if (isLoggedIn && !isLoginRoute) next();
  else if (!isLoggedIn && isLoginRoute) next();
  else if (!isLoggedIn && !isLoginRoute) next({ name: "Login" });
});

export default router;
