import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import de from "vuetify/src/locale/de";

export default new Vuetify({
  lang: { locales: { de }, current: "de" },
});
